export const teamData = [
    {
        key: 'BabyJeanRodriguez',
        fullName: 'Baby Jean Rodriguez',
        content: "As an HR Administrator, she is adept at managing the day-to-day operations of the human resources department, ensuring that all HR functions are seamlessly integrated with the organization's goals. She has extensive experience in recruitment, employee onboarding, benefits administration, and compliance with employment laws and regulations. She is skilled in maintaining employee records, handling payroll, and facilitating communication between management and employees. Her strong organizational skills and attention to detail enable her to effectively manage multiple HR tasks while maintaining a positive and productive work environment. She is also experienced in resolving employee relations issues, providing support to staff, and implementing HR policies and procedures that align with company objectives",
        position: 'Human Resource Admin',
        imgURL: '/assets/jean.jfif',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'AlbertPagaduan',
        fullName: 'Albert Pagaduan',
        content: "As a Lead Software Engineer and Project Manager, he excels in steering complex projects from conception through to delivery while ensuring the highest standards of software development. His dual expertise in software engineering and project management allows him to effectively bridge the gap between technical teams and project objectives. He brings extensive experience in leading development teams, designing and implementing scalable software solutions, and managing project timelines and budgets. His proficiency in modern technologies and methodologies, such as Agile and Scrum, enables him to drive projects efficiently and adapt to evolving requirements. He is skilled in coordinating with stakeholders, defining project scopes, and managing risks to ensure that deliverables meet quality standards and align with business goals. His leadership and communication skills help foster collaboration, motivate team members, and resolve issues, ensuring that projects are completed on time and within scope.",
        position: 'Lead Software Engineer/Project Manager',
        imgURL: '/assets/rev.jfif',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'JhumerApus',
        fullName: 'Jhumer O. Apus',
        content: `As a Senior Front-End Engineer with extensive experience in crafting high-performance, user-centric web applications, Jhumer have a deep understanding of modern web technologies and frameworks. His expertise lies in React, Next.js, Vue, Nuxtjs, Ant Design, and MuiCore, where he excel at creating scalable, maintainable, and accessible codebases. He have a strong foundation in JavaScript, TypeScript, HTML, and CSS, and he is skilled in optimizing applications for performance and responsiveness. Throughout his career, He'd successfully led the development of complex projects, collaborating closely with cross-functional teams to deliver seamless user experiences. His passion for staying updated with the latest industry trends and best practices ensures that he bring innovative solutions to the table, continuously enhancing the quality and efficiency of the applications he work on.`,
        position: 'Senior Front-End Engineer',
        imgURL: '/assets/jhumer_pic.jpg',
        fbUrl: 'https://www.facebook.com/jhumer.apus.1/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/in/jhumer-apus-10b32a1ab/',
    },
    {
        key: 'MarcRovicBaja',
        fullName: 'Marc Rovic Baja',
        content: "As a Senior Back-End Engineer, he specializes in architecting and implementing robust, scalable, and secure server-side applications. With deep expertise in languages like Node.js and Python. along with extensive experience in databases such as PostgreSQL, MongoDB, and MySQL, he is proficient in building efficient APIs and cloud-based solutions. He has a strong background in system design, and performance optimization, including continuous integration and deployment (CI/CD). His experience includes working on high-traffic applications where reliability, security, and performance are critical. He thrives in collaborative environments, often taking the lead in technical decision-making, mentoring junior developers, and ensuring that projects meet both technical and business objectives",
        position: 'Senior Back-End Engineer',
        imgURL: '/assets/marc.jfif',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'WilsonVargas',
        fullName: 'Wilson Vargas',
        content: "As a Senior QA Engineer, he is dedicated to ensuring the highest standards of software quality and reliability. With extensive experience in both manual and automated testing, he excels at identifying defects, improving processes, and implementing robust testing strategies that align with project goals.He has a deep understanding of various testing methodologies, including functional, regression, performance, and security testing. His expertise extends to working with tools like Selenium, JIRA, and Jenkins, enabling him to design and execute comprehensive test plans that cover all aspects of the software development lifecycle. He is proficient in creating detailed test cases, managing defect lifecycles, and collaborating closely with development teams to ensure that issues are resolved efficiently. His commitment to quality and continuous improvement drives him to stay updated with the latest testing tools and practices, ensuring that every release meets the highest quality standards.",
        position: 'Senior Quality Assurance Engineer',
        imgURL: '/assets/wilson.jfif',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'MarkAndreiNebres',
        fullName: 'Mark Andrei Nebres',
        content: "As a Business Development Officer, he is focused on driving the growth and success of the organization through strategic planning, relationship building, and market expansion. With a proven track record in identifying new business opportunities, developing strategic partnerships, and managing key accounts, he excels at creating and executing strategies that align with the company’s objectives. He has extensive experience in market research, competitive analysis, and lead generation, enabling him to identify emerging trends and opportunities that drive revenue growth. His strong communication and negotiation skills help him forge and maintain successful relationships with clients, stakeholders, and partners. He is adept at crafting compelling proposals, managing sales pipelines, and coordinating with cross-functional teams to ensure the successful execution of business initiatives. His ability to analyze market dynamics and adapt strategies accordingly ensures that he contributes effectively to the organization’s long-term success",
        position: 'Business Development Officer',
        imgURL: '/assets/andrei.jfif',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'JMSalado',
        fullName: 'JM Salado',
        content: "As a Business Development Officer, he plays a crucial role in fostering growth and expanding market presence. With a strong background in strategic planning and market analysis, he is skilled in identifying and capitalizing on new business opportunities that drive profitability and enhance the company’s competitive edge. He excels in building and nurturing relationships with potential clients, partners, and stakeholders, utilizing his expertise in negotiation and communication to close deals and secure valuable partnerships. His approach is data-driven, leveraging insights from market research and competitive analysis to inform strategic decisions and optimize business development efforts. His responsibilities include managing sales strategies, developing business proposals, and coordinating with internal teams to ensure the successful implementation of new initiatives. His proactive and results-oriented mindset ensures that he consistently contributes to the company's growth and success.",
        position: 'Business Development Officer',
        imgURL: '/assets/jm.jfif',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
/*     {
        key: 'Fritzgerald',
        fullName: 'Fritzgerald L. Monzon',
        content: 'lorem ipsum',
        position: 'Front-End Engineer Intern',
        imgURL: '',
        imgURL: '/assets/fritz.jpg',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'richelle',
        fullName: 'Richelle Rosario',
        content: 'lorem ipsum',
        position: 'Human Resource Intern',
        imgURL: '/assets/richelle.jpg',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'roselle',
        fullName: 'Roselle Vergara',
        content: 'lorem ipsum',
        position: 'Human Resource Intern',
        imgURL: '/assets/roselle.jfif',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'maryl',
        fullName: 'Mary Laine Hingada',
        content: 'lorem ipsum',
        position: 'Marketing Intern',
        imgURL: '',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'marym',
        fullName: 'Mary Mae Aganinta',
        content: 'lorem ipsum',
        position: 'Marketing Intern',
        imgURL: '',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'yashley',
        fullName: 'Yashley Vien Camatog',
        content: 'lorem ipsum',
        position: 'Marketing Intern',
        imgURL: '',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    }, */
]

export const internData = [
    {
        key: 'Fritzgerald',
        fullName: 'Fritzgerald L. Monzon',
        content: 'lorem ipsum',
        position: 'Front-End Engineer Intern',
        imgURL: '/assets/fritz.jpg',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'richelle',
        fullName: 'Richelle Rosario',
        content: 'lorem ipsum',
        position: 'Human Resource Intern',
        imgURL: '/assets/richelle.jpg',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'roselle',
        fullName: 'Roselle Vergara',
        content: 'lorem ipsum',
        position: 'Human Resource Intern',
        imgURL: '/assets/roselle.jfif',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'maryl',
        fullName: 'Mary Laine Hingada',
        content: 'lorem ipsum',
        position: 'Marketing Intern',
        imgURL: '',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'marym',
        fullName: 'Mary Mae Aganinta',
        content: 'lorem ipsum',
        position: 'Marketing Intern',
        imgURL: '',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
    {
        key: 'yashley',
        fullName: 'Yashley Vien Camatog',
        content: 'lorem ipsum',
        position: 'Marketing Intern',
        imgURL: '',
        fbUrl: 'https://www.facebook.com/',
        twitterURL: 'https://twitter.com/',
        linkedInURL: 'https://www.linkedin.com/',
    },
]